.contact-social a {
  font-size: 2em;
}

.contact-social a {
  margin-left: 35px;
}
.contact-info {
  color: #4a4e69;
}
.social-buttons {
  font-size: 2em;
  background-color: #22223b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.contact-info .card {
  border: none;
  border-bottom: 10px solid #22223b;
  border-radius: 5px;
}

a.social-button {
  line-height: 0;
}

#contact-details {
  background-color: var(--lg-light);
}
